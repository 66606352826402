import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {Link, useLocation, useParams} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Buscador from "./Buscador";
import CategoriasSide from "./CategoriasSide";
import CategoriasBreadcrumbs from "./CategoriasBreadcrumbs";
import Producto from "./Producto";
import productosStore from "../stores/ProductosStore";
import Loading from "./Loading";
import { NoIVA } from "./Footer";
import CarouselMarcas from "./CarouselMarcas";
import usuariosStore from "../stores/UsuariosStore";
import ScrollToTop from "../utils/ScrollToTop";

function Catalogo(props) {
    const { query, catId, familia } = useParams();
    const { scroll = true, simple, resXpag = 12, titulo, novedades } = props;
    const [state, setState] = useState({
        page: 0,
        pages: 0,
        runningPage: 0,
        productos: [],
        currentCatId: undefined,
        family: undefined,
        currentNovedades: novedades,
        cache: {}
    });

    const listar = () => {
        if (state.runningPage === state.page + 1) return;
        if (state.page !== 0 && state.page === state.pages) return;
        productosStore.listarProductos(
            novedades ? undefined : state.currentCatId,
            novedades ? undefined : state.family,
            novedades ? undefined : query,
            state.page + 1,
            resXpag,
            novedades ? 'C' : 'A',
            novedades ? 'DESC' : 'ASC'
        ).then(() => {
            setState({
                page: productosStore.productos.page,
                pages: productosStore.productos.pages,
                runningPage: state.page + 1,
                productos: state.productos.concat(productosStore.productos.data),
                currentCatId: state.currentCatId,
                family: state.family,
                currentNovedades: novedades
            });
        });
    };

    useEffect(() => {
        if (
            catId !== state.currentCatId ||
            familia !== state.family ||
            novedades !== state.currentNovedades
        ) {
            setState({
                page: 0,
                pages: 0,
                runningPage: 0,
                productos: [],
                currentCatId: catId,
                family: familia,
                currentNovedades: novedades
            });
        }
        if (state.page === 0) {
            listar();
        }
        const handleScroll = (e) => {
            if (!scroll) return;
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight + 300 >= scrollHeight) {
                listar();
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    const loadingCSS = {
        height: '100px',
        margin: '100px',
        display: productosStore.cargando ? "block" : "none"
    };

    if (simple) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-center mb-3 ">
                        <h3 className="bold">{titulo}</h3>
                        <h5 className="titulo-link"><Link to={'/catalogo'}>VER EL CATÁLOGO COMPLETO</Link></h5>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    { !productosStore.cargando && state.productos.length === 0 &&
                        <span>No hay productos para mostrar.</span>
                    }
                    { state.productos.map(prod =>
                        <Producto prod={prod} key={prod.id} wide />
                    )}
                </div>
                <div style={loadingCSS}> <Loading /> </div>
                <NoIVA />
            </div>
        )
    }

    return (
        <div className="container-fluid px-0 mx-0" id="catalogo">
            <ScrollToTop />
            <div className="container">
                <CarouselMarcas />
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bc-bg">
                        <CategoriasBreadcrumbs />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                        <CategoriasSide novedades={novedades} />
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 lista-productos">
                        <div className="row my-lg-4 mt-md-0 mb-md-4 mt-sm-0 mb-sm-4 mt-0 mb-4">
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 descargar">
                                {(usuariosStore.logged) &&
                                    <button className={'btn btn-descargar'} onClick={() =>
                                        productosStore.setShowDownloadModal(!productosStore.showDownloadModal)
                                    }>
                                        Descargá el catalogo <i className="fa-solid fa-download"></i>
                                    </button>
                                }
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 buscador-catalogo">
                                <Buscador />
                            </div>
                        </div>

                        <div className="row listado-angosto">
                            { !productosStore.cargando && state.productos.length === 0 &&
                                <span>No hay productos para mostrar.</span>
                            }
                            { state.productos.map(prod =>
                                <Producto prod={prod} key={prod.id} />
                            )}
                        </div>
                        <div style={loadingCSS}> <Loading /> </div>
                    </div>
                </div>
            </div>
            <NoIVA />

            <Modal
                show={productosStore.showDownloadModal}
                onHide={() => productosStore.setShowDownloadModal(false)}
                backdrop={'static'}
                keyboard={false}
                className="descarga-catalogo"
            >
                <Modal.Header>
                    <h4 className="modal-title " id="">Descargar el Catálogo de Productos</h4>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() =>
                        productosStore.setShowDownloadModal(false)
                    }></button>
                </Modal.Header>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <h4>Districentro Ferretera</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <button type="submit" className="" onClick={() => {
                                window.open('https://districentro.openplus.net.ar/cdn/archivos/3.xls')
                            }}>
                                EXCEL (.xls) <i className="fa-solid fa-file-excel" style={{fontSize: '1.3em'}}></i>
                            </button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <button type="submit" className="" onClick={() => {
                                window.open('https://districentro.openplus.net.ar/cdn/archivos/4.pdf')
                            }}>
                                PDF (.pdf) <i className="fa-solid fa-file-pdf" style={{fontSize: '1.3em'}}></i>
                            </button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <h4>Leonardo</h4>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <button type="submit" className="" onClick={() => {
                                window.open('https://districentro.openplus.net.ar/cdn/archivos/2.xls')
                            }}>
                                EXCEL (.xls) <i className="fa-solid fa-file-excel" style={{fontSize: '1.3em'}}></i>
                            </button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <button type="submit" className="" onClick={() => {
                                window.open('https://districentro.openplus.net.ar/cdn/archivos/1.pdf')
                            }}>
                                PDF (.pdf) <i className="fa-solid fa-file-pdf" style={{fontSize: '1.3em'}}></i>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default observer(Catalogo);